<template>
  <section >
    <div>
       <h2 class="titulo-links-escolha" >EDITAR TURMA</h2>
    </div>
    <div v-if="turmaAtivada == 1">
       <p>*APENAS O NOME E O TURNO PODEM SER EDITADO, CASO A TURMA JÁ ESTEJA ATIVADA</p>
    </div>

    <pm-Card>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-8 lg:col-3">
            <label>Selecione a Escola</label>
            <select class="p-inputtext p-component" v-model="escolaInfo"
              style="appearance: revert !important;width: 100% !important;" :disabled="turmaAtivada == 1" >
                <option value="" disabled selected> -- Escolha uma Escola -- </option>
                <option v-for="n in escolaALL" :key="n" :value="n.id" >{{n.nome}}</option>
            </select>
          </div>
          <div class="field col-12 md:col-6 lg:col-2">
            <label>Nome da turma</label>
            <pm-InputText  v-model="turma.nome" />
          </div>
          <div class="field col-12 md:col-4 lg:col-1">
            <label>Selecione o Turno</label>
            <select class="p-inputtext p-component" v-model="turno"
              style="appearance: revert !important;width: 100% !important;" >
                <option v-for="n in horarios" :key="n" :value="n.nome" >{{n.nome}}</option>
            </select>
          </div>

          <div class="field col-12 md:col-5 lg:col-3">
            <label>Selecione o Segmento</label>
            <select class="p-inputtext p-component" v-model="segmentoInfo" @change="listaSerie(segmentoInfo)" :disabled="turmaAtivada == 1"
              style="appearance: revert !important;width: 100% !important;" >
                <option value="null" disabled selected> -- Escolha o Segmento -- </option>
                <option v-for="n in segmentoALL" :key="n" :value="n.id" >{{n.nome}}</option>
            </select>
          </div>
          <div class="field col-12 md:col-7 lg:col-3">
            <label>Selecione o Período:</label>
            <select class="p-inputtext p-component"
              style="appearance: revert !important;width: 100% !important;" v-model="serieInfo" :disabled="turmaAtivada == 1">
                <option value="null" disabled selected> -- Escolha o Período -- </option>
                <option v-for="a in serieALL" :key="a.id" :value="a.id" >{{a.nome}}</option>
            </select>
          </div>
        </div>
        <div class="grid grid-nogutter justify-content-between">
          <pm-Button label="Voltar"  class="p-button-danger" icon="pi pi-angle-left" iconPos="left" @click="voltar()" />

          <pm-Button   class="p-button-success"
             label="Editar" @click="editar()"
             icon="pi pi-check" iconPos="right"
           />
        </div>
      </template>
    </pm-Card>

  </section >
</template>

<script>

import { Turma } from "@/class/turma.js";
import { Escola } from "@/class/escolas.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { SeriesEscolar } from "@/class/serie.js";
import { Configuracao } from "@/class/configuracao.js";

import vueMask from 'vue-jquery-mask';
import store_token_info from "@/store/store_token_info.js";


export default {
  props: {
    id:{},
    turmaAtivada: {},
    pg:{}
  },
  components: {},
  name: 'turma editar',
  data () {
    return {
      turma: {
        id: null,
        ano: null,
        escola_id: null,
        segmento_id: null,
        serie_id: null,
        nome: null,
        turno: null,
        user_id: store_token_info.usuario_logado,
      },
      turno:null,
      escolaInfo : '',
      escolaALL : [],
      segmentoInfo : '',
      segmentoALL : [],
      serieInfo : '',
      serieALL : [],
      isLoading: false,
      errorMessages: ['Digite um CEP válido!'],
      horarios: [],
    }
  },
  methods: {
    clear (field) {
      this[field] = ''
    },
    voltar(){
      this.$router.push({name: 'turmas', params: {pg: this.pg} });
    },
    async buscarTurnoALL() {
      /*let data = await Configuracao.obtemTodos();
      this.horarios = data.data[0].turnos;*/
      let turnos = [{nome: "Matutino"}, {nome: "Vespertino"}, {nome: "Noturno"}, {nome: "Diurno"}]
      this.horarios = turnos;
    },
    async listaSerie (id) {
      let data = await SegmentoEscolar.listarSegmentosPorSerie(id);
      this.serieALL = data.data;
      return this.seriesALL;
    },
    async buscarEscolaALL() {
      let data = await Escola.obtemTodos();
      this.escolaALL = data.data;
    },
    async buscarSegmentoALL() {
      let data = await SegmentoEscolar.obtemTodos();
      this.segmentoALL = data.data;
    },
    async buscarSerieALL() {
      let data = await SeriesEscolar.obtemTodos();
      this.serieALL = data.data;
    },
    async editarOption(id){
        try {
          let data = await Turma.obtemUm(id);
          this.turma.id = data.data.id;
          this.turma.ano = data.data.ano;
          this.turma.escola_id = data.data.escola_id;
          this.escolaInfo =  this.turma.escola_id;
          this.turma.segmento_id = data.data.segmento_id;
          this.segmentoInfo = this.turma.segmento_id;
          this.listaSerie(this.segmentoInfo);
          this.turma.serie_id = data.data.serie_id;
          this.serieInfo = this.turma.serie_id;
          this.turma.nome = data.data.nome;
          this.turno = data.data.turno;
        } catch (e) {
          console.log(e);
        }
    },
    async editar() {
      try {
        if(this.segmentoInfo != undefined){
          this.turma.segmento_id = this.segmentoInfo;
        }
        if(this.serieInfo != undefined){
          this.turma.serie_id = this.serieInfo;
        }

        if(this.escolaInfo != undefined){
          this.turma.escola_id = this.escolaInfo;
        }

        this.turma.turno = this.turno;

        if(this.turma.turno == undefined){
          this.turma.turno = this.turno;
        }

        sessionStorage.setItem("TurmaConfg_etapa_id",  this.turma.segmento_id);
        sessionStorage.setItem("TurmaConfg_periodo_id", this.turma.serie_id);
        sessionStorage.setItem("TurmaConfg_turma_id", this.turma.id);
        sessionStorage.setItem("TurmaConfg_escola_id", this.turma.escola_id);
        sessionStorage.setItem("TurmaConfg_turno", this.turma.turno);
        sessionStorage.setItem("erroturmaeditar",1);

        const data = await Turma.editar(this.id,this.turma);
        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.$router.push({ name: "turmas", params: {pg: this.pg} });
      }
      catch(e) {

        if(e.response.data.tipo == 1){
          this.$vaToast.init({
            message: e.response.data.erro,
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 2500,
            fullWidth: false,
            color: 'warning'
          })

        }else{
          this.$vaToast.init({
            message: "Erro ao editar!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 2500,
            fullWidth: false,
          })
        }
      }
    },
  },
  beforeMount() {

    if (this.id == null) {
      this.$router.push({name: 'turmas'});
    }
    else {
      this.buscarEscolaALL();
      this.buscarSegmentoALL();
      //this.buscarSerieALL();
      this.buscarTurnoALL();
      this.editarOption(this.id);
    }
  },
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>
